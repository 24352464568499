import Controller from './application_controller'
import Tour from '../models/tour'

export default class extends Controller {
  static values = { selectedTour: Object, enabled: Boolean }
  static targets = ['form', 'cancel']

  connect() {
    if (!this.hasEnabledValue) return
    if (!this.hasSelectedTourValue) return
    if (Object.keys(this.selectedTourValue).length === 0) return

    const attachableTour = this.attachableTour
    if (attachableTour && !this.attachableElementHidden(attachableTour)) {
      this.waitForAttachableElement()
    } else {
      this.start()
    }
  }

  waitForAttachableElement() {
    if (this.isElementVisible(this.attachableElement) && this.shouldStart()) {
      this.start()
    } else {
      this.observeDOM()
    }
  }

  observeDOM() {
    const observer = new MutationObserver(() => {
      if (this.isElementVisible(this.attachableElement) && this.shouldStart()) {
        this.start()
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['style', 'class']
    })
  }

  isElementVisible(element) {
    return element && element.offsetParent !== null
  }

  shouldStart() {
    return !document.body.dataset.modalOpen
  }

  disconnect() {
    this.element.remove()
  }

  nextStep() {
    requestAnimationFrame(() => this.tour?.next())
  }

  completeTour() {
    this.tour?.complete()
    document.body.setAttribute('completed-tour', true)
    this.element.remove()
  }

  // Callbacks

  handleCancel(slug) {
    this.cancelTarget.value = true
    this.formTarget.requestSubmit()
  }

  handleComplete(slug) {
    this.formTarget.requestSubmit()
  }

  start() {
    if (!this.shouldStart()) return

    this.tour = new Tour(
      this.selectedTourValue,
      this.handleCancel.bind(this),
      this.handleComplete.bind(this)
    )

    this.tour.start()
  }

  get attachableTour() {
    const tour = this.selectedTourValue.steps.find(step => step.attachTo)

    return tour
  }

  attachableElementHidden(tour) {
    const attachableElement = document.querySelector(tour?.attachTo?.element)
    if (!attachableElement) return false
    if (attachableElement.classList.contains('invisible')) return true
    if (attachableElement.classList.contains('hidden')) return true

    return false
  }

  get attachableElement() {
    return document.querySelector(this.attachableTour.attachTo.element)
  }
}
